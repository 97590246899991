<template>
  <b-card class="border-0">
    <div>
      <b-row>
        <b-col md="6">
          <p>
            <strong>Data Creazione</strong>
            <br />
            {{userCreatedAt}}
          </p>
        </b-col>
        <b-col md="6">
          <p>
            <strong>Data Modifica</strong>
            <br />
            {{userLastModified}}
          </p>
        </b-col>
        <b-col md="6">
          <p>
            <strong>Status</strong>
            <br />
            <badge
              :status="userAnalysis.status"
              statuses="analysis"
            />
          </p>
        </b-col>
        <b-col md="6">
          <p>
            <strong>Soggetto analizzato</strong>
            <br />
            {{referentData}}
          </p>
        </b-col>
        <b-col md="12">
          <p>
            <strong>Richiesta</strong>
            <br>
            <em>{{userAnalysis.labelRequestType}}</em>
            <br>
            {{userAnalysis.labelRequestProduct}}
          </p>
        </b-col>
      </b-row>
    </div>
    <b-card v-if="isProcessed" class="mt-3">
      <template #header>
        <card-header
          title="Documenti"
        />
      </template>

      <document-list
        :entity-id="userAnalysis.requestName"
        :entity-class="entityClass"
        :documents="documents"
        slim
      />
    </b-card>
  </b-card>
</template>

<script>
import { USER_ANALYSIS_CLASS } from '@/utils/interfaces';
import { isNotExist, isPresent } from '@/utils/validators';
import { formatDateTime } from '@/utils/formatterHelper';

const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const Badge = () => import('@/components/helpers/Badge.vue');
const DocumentList = () => import('@/components/documents/DocumentList.vue');

export default {
  name: 'UserAnalysisDetailInfo',
  components: { DocumentList, Badge, CardHeader },
  props: {
    userAnalysis: { ...USER_ANALYSIS_CLASS },
  },
  data() {
    return {
      entityClass: 'USERANALYSIS',
    };
  },
  computed: {
    documents() {
      return this.$store.getters['documents/getDocumentList'](this.entityClass, this.userAnalysis.requestName);
    },
    referentToPerson() {
      return isPresent(this.userAnalysis.referentInfo.personId);
    },
    referentData() {
      if (this.referentToPerson) {
        // eslint-disable-next-line max-len
        return `${this.userAnalysis.referentInfo.name} ${this.userAnalysis.referentInfo.surname} (${this.userAnalysis.referentInfo.taxCode})`;
      }
      return `${this.userAnalysis.referentInfo.companyName} (${this.userAnalysis.referentInfo.vatNumber})`;
    },
    userCreatedAt() {
      return formatDateTime(this.userAnalysis.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.userAnalysis.lastModified);
    },
    isProcessed() {
      const { status } = this.userAnalysis;
      return status === 'COMPLETED';
    },
    headingText() {
      return `Dettaglio Richiesta: ${this.userAnalysis.requestName}`;
    },
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    loadDocuments() {
      console.debug('Load documents', this.userAnalysis);
      if (isNotExist(this.userAnalysis)) return;
      if (this.isProcessed) {
        this.$store.dispatch('documents/loadDocuments', {
          entityClass: this.entityClass,
          entityId: this.userAnalysis.requestName,
        });
      }
    },
    backToList() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>

</style>
